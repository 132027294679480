'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Text } from '@/components';
import { Button, TextInput } from '@/components/form';
import IconIntelliflo from '@/components/icon/icon-intelliflo';
type Inputs = {
  password: string;
  email: string;
};
interface LoginFormProps {
  intelliflo?: boolean;
}
export const LoginForm = (props: LoginFormProps) => {
  const {
    intelliflo
  } = props;
  const router = useRouter();
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl') || searchParams.get('x-iflo-returnUrl') || '/';
  const [loading, setLoading] = useState(false);
  const intellifloSignin = async () => {
    signIn('intelliflo', {
      callbackUrl
    });
  };
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    setError
  } = useForm<Inputs>({
    defaultValues: {
      email: searchParams.get('email') || undefined
    }
  });
  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    try {
      const res = await signIn('credentials', {
        email: data.email,
        password: data.password,
        redirect: false
      });
      if (res?.error === null) {
        router.push(callbackUrl);
      } else {
        setError('email', {
          type: 'manual',
          message: 'Invalid email or password'
        });
      }
    } catch (error) {
      console.error(error);
      setError('email', {
        type: 'manual',
        message: 'Invalid email or password'
      });
    }
    setLoading(false);
  };
  return <div className="flex flex-col items-center" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <Image src="/assets/images/rel_white.png" alt="Company Logo" width={250} height={100} data-sentry-element="Image" data-sentry-source-file="LoginForm.tsx" />
      <Text className="mt-10 text-white" variant="label" fontSize="xxl" data-sentry-element="Text" data-sentry-source-file="LoginForm.tsx">
        Log in
      </Text>
      <div className="mt-5 rounded-lg border border-white bg-[#262626] p-8">
        {/* <Button className="w-full" size="lg" variant="dark" onClick={() => {}}>
          <div className="flex items-center">
            <div className="absolute left-4">
              <IconGoogle size={20} />
            </div>
            Sign in with Google
          </div>
         </Button> */}
        {intelliflo && <>
            <Button className="mt-6 w-full" size="lg" variant="dark" onClick={intellifloSignin}>
              <div className="flex items-center justify-between">
                <div className="absolute left-4">
                  <IconIntelliflo />
                </div>
                Sign in with Intelliflo
              </div>
            </Button>
            <div className="mt-5 flex w-full items-center justify-center">
              <hr className="border-gray w-full border-t" />
              <Text className="mx-4 text-gray-400" variant="label" fontSize="lg">
                OR
              </Text>
              <hr className="border-gray w-full border-t" />
            </div>
          </>}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5 sm:w-96">
          <TextInput label="Email" type="email" labelClass="text-white" {...register('email', {
          required: 'field is required'
        })} error={errors.email?.message} data-sentry-element="TextInput" data-sentry-source-file="LoginForm.tsx" />
          <TextInput label="Password" type="password" className="mt-6" labelClass="text-white" {...register('password', {
          required: 'field is required',
          minLength: {
            value: 9,
            message: 'Password must be at least 9 characters long'
          }
        })} error={errors.password?.message} data-sentry-element="TextInput" data-sentry-source-file="LoginForm.tsx" />
          <Button className="mt-10 w-full" variant="primary" type="submit" loading={loading} data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
            Log in with email
          </Button>
        </form>
        <div className="flex-auto mt-10 text-white-dark">
          <Link href="/register-organisation" className="underline hover:no-underline flex justify-center hover:text-white" data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">
            Register a new account for your organisation
          </Link>
        </div>
        <div className="flex-auto mt-10 text-white-dark">
          <Link href="/reset-password-initiate" className="underline hover:no-underline flex justify-center hover:text-white" data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">
            I forgot my password
          </Link>
        </div>
      </div>
    </div>;
};