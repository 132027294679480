import { FC } from 'react';
interface IconIntellifloProps {
  className?: string;
  size?: number;
}
const IconIntelliflo: FC<IconIntellifloProps> = ({
  size = 80
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" id="intelliflo_primary_logo" data-name="intelliflo primary logo" width={size} height={size * 148.624 / 418.835} viewBox="0 0 418.835 148.624" data-sentry-element="svg" data-sentry-component="IconIntelliflo" data-sentry-source-file="icon-intelliflo.tsx">
      <path id="Path_1" data-name="Path 1" d="M74.312,0a74.312,74.312,0,0,0,0,148.624H344.523A74.312,74.312,0,1,0,344.523,0H74.312" fill="#113ad6" data-sentry-element="path" data-sentry-source-file="icon-intelliflo.tsx"></path>
      <path id="Path_2" data-name="Path 2" d="M52.392,36.507a5.41,5.41,0,0,1-5.3,5.374,5.375,5.375,0,0,1,0-10.749,5.409,5.409,0,0,1,5.3,5.374M43.418,94.281h7.356V49.494H43.418ZM82.7,48c-6.62,0-11.475,2.836-14.565,7.166V49.494H60.778V94.281h7.356V70.1c0-9.405,5-15.377,12.358-15.377,6.914,0,10.151,3.881,10.151,11.794V94.131H98V65.02C98,54.57,92.261,48,82.7,48Zm53.256,29.41c.294,6.867-2.942,11.346-8.533,11.346s-8.974-3.284-8.974-9.853V56.212h23.1V49.494h-23.1V35.76h-7.356V49.345H102.56v6.718h8.533V78.605c0,10.6,6.326,16.87,16.624,16.87,8.974,0,15.889-6.718,15.3-17.616l-7.062-.448Zm47.077,0,7.062,1.344c-1.324,9.853-9.71,16.869-20.3,16.869-13.388,0-22.215-10.152-22.215-23.737,0-13.436,8.533-23.737,21.626-23.737,11.916,0,19.714,8.659,19.714,20.9v3.881H154.639c.589,9.7,6.473,15.974,15.153,15.974C176.56,88.757,182,84.428,183.033,77.411Zm-28.1-10.9h26.775c-.147-6.718-5.149-11.794-12.652-11.794C161.848,54.72,156.7,59.646,154.933,66.513ZM197.45,94.281h7.356V28.893H197.45Zm17.213,0h7.356V28.893h-7.356ZM235.7,31.132a5.314,5.314,0,0,0-5.3,5.374,5.3,5.3,0,1,0,10.593,0A5.51,5.51,0,0,0,235.7,31.132Zm-3.678,63.148h7.356V49.494h-7.356Zm38.4-66.881c-10,0-16.771,7.315-16.771,17.317v4.628h-8.386v6.718h8.386V94.131h7.356V56.212h12.5V49.494h-12.5V44.717c0-6.121,3.825-10.152,9.415-10.152,5,0,8.239,3.284,8.239,8.509V94.43h7.356v-52.1C286.162,33.222,279.542,27.4,270.421,27.4Zm69.292,44.487c0,13.287-9.268,23.737-22.656,23.737-13.682,0-22.8-10.3-22.8-23.737,0-13.287,9.121-23.886,22.8-23.886C330.444,48,339.713,58.6,339.713,71.888Zm-7.356,0c0-9.554-6.473-16.72-15.3-16.72-8.974,0-15.3,7.166-15.3,16.72s6.326,16.571,15.3,16.571C325.884,88.458,332.357,81.591,332.357,71.888Z" transform="translate(20.602 12.727)" fill="#fff" data-sentry-element="path" data-sentry-source-file="icon-intelliflo.tsx"></path>
    </svg>;
};
export default IconIntelliflo;